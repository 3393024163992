function AboutPage() {
  return (
    <main>
      <h2>About</h2>
      <p>about us page</p>
    </main>
  );
}

export default AboutPage;
